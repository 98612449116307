<template>
    <Layout>
        <div v-if="legalOrganization.legalOrganization" class="short-name">
            {{ legalOrganization.legalOrganization.licenseSchoolName }}
        </div>

                <OrganizationCard
                    :data="{ organization: this.cardOrganization }"
                    class="organization-card"
                    style="margin-top: 50px"
                />
        <div v-if="legalOrganization.legalOrganization" class="info-cards">
            <div style="flex: 1">

                <div class="main-info card">
                    <div class="labels">
                        <div>Руководитель</div>
                        <div>Статус лицензии</div>
                        <div>Дата выдачи лицензии</div>
                    </div>

                    <div class="values">
                        <div>{{ organizationManager }}</div>
                        <div>{{ legalOrganization.legalOrganization.licenseStatusName }}</div>
                        <div>{{ legalOrganization.legalOrganization.licenseDateLicDoc }}</div>
                    </div>

                    <div class="img"></div>
                </div>

                <div class="achievements card" v-if="legalOrganization.raex || (legalOrganization.qs.length != 0 && legalOrganization.qs != '0' )">
                    <div class="label">Достижения</div>
                    <div v-if="legalOrganization.raex" class="achievement">
                        <div class="value">№{{legalOrganization.raex}}</div>
                        <div class="clarification">в рейтинге RAEX</div>
                    </div>

                    <div class="achievement" v-if="legalOrganization.qs.length != 0 && legalOrganization.qs != '0'">
                        <div class="value">№{{legalOrganization.qs}}</div>
                        <div class="clarification">в рейтинге qs</div>
                    </div>
                </div>
            </div>

            <div class="right-panel card">
                <div class="label">Коды статистики</div>

                <div class="codes">
                    <div class="labels">
                        <div>ИНН</div>
                        <div>ОГРН</div>
                        <div>ОКВЭД</div>
                    </div>

                    <div class="values">
                        <div>{{ legalOrganization.legalOrganization.licenseInn }}</div>
                        <div>{{ legalOrganization.legalOrganization.licenseOgrn }}</div>
                        <div>{{ cardOrganization.okved }}</div>
                    </div>
                </div>

                <div class="label" style="margin-top: 30px">Контакты</div>

                <div class="contacts">
                    <!-- <div style="margin-top: 25px; margin-bottom: 16px">
                        <v-icon color="primary">email</v-icon>
                        <span style="margin-left: 10px">e@stankin.ru</span>
                    </div>

                    <div style="margin-bottom: 16px">
                        <v-icon color="primary">phone</v-icon>
                        <span style="margin-left: 10px">(499) 973-30-76</span>
                    </div> -->

                    <div>
                        <v-icon color="primary">language</v-icon>
                        <a style="margin-left: 10px" target="_blank" :href="site">{{cardOrganization.site}}</a>
                    </div>
                </div>

                <div v-if="links" class="links">
                    <div class="label" style="margin-top: 30px">
                        Соцсети
                    </div>

                    <a v-for="instLink in links.instagram" :href="instLink" target="_blank">
                        <img src="../assets/img/instagram-icon.svg" />
                    </a>

                    <a v-for="vkLink in links.vk" :href="vkLink" target="_blank">
                        <img src="../assets/img/vk-icon.svg" />
                    </a>

                    <a v-for="facebookLink in links.facebook" :href="facebookLink" target="_blank">
                        <img src="../assets/img/facebook-icon.svg" />
                    </a>

                    <a v-for="okLink in links.ok" :href="okLink" target="_blank">
                        <img src="../assets/img/odnoklassniki-icon.svg" />
                    </a>

                    <a v-for="ytLink in links.youtube" :href="ytLink" target="_blank">
                        <img src="../assets/img/youtube-icon.svg" />
                    </a>
                </div>
            </div>
        </div>

        <v-expansion-panels style="margin: 30px 0; border: 1px solid #e5e7eb" flat>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Образовательные программы
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-for="program in legalOrganization.programs" class="program">
                        <span
                            v-if="program.licensedProgramCode"
                            style="font-weight: 500; font-size: 18px;"
                        >
                            {{ program.licensedProgramCode }} -
                        </span>
                        {{ program.licensedProgramName }}

                        <div class="qual-name">
                            <v-icon color="primary">school</v-icon>
                            <span style="margin-left: 8px">{{
                                program.licensedProgramQualName
                            }}</span>
                        </div>

                        <div class="type">
                            <v-icon color="primary">business</v-icon>
                            <span style="margin-left: 8px">{{
                                program.licenseEduProgramType
                            }}</span>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import OrganizationCard from "@/components/OrganizationCard";
import httpClient from "@/client/httpClient";

export default {
    name: "OrganizationProfile",
    components: { OrganizationCard, Layout },
    data() {
        return {
            cardOrganization: null,
            legalOrganization: {},
            dadataInfo: null,
            links: null
        };
    },
    mounted() {
        const organizationSite = this.$route.query.site;
        if (organizationSite) {
            this.retrieveOrganizationSocials(organizationSite);
        }

        this.loadOrganizationData();
    },
    computed: {
        organizationManager() {
            const manager = this.dadataInfo?.suggestions[0]?.data?.management?.name;

            if (manager) {
                return manager;
            }

            return "-";
        },
        site(){
            if (this.cardOrganization.site.split(',')[0]){
                return "https://" + this.cardOrganization.site.split(',')[0]
            }
        }
    },
    methods: {
        async retrieveOrganizationSocials(organizationSite) {
            this.links = (
                await httpClient().post(`linkextractor`, `https://${organizationSite}`)
            ).data;
        },
        async loadOrganizationData() {
            const id = this.$route.params.id;
            this.cardOrganization = (await httpClient().get(`/org/${id}`)).data;

            httpClient()
                .get(`/org/legal/${this.cardOrganization.inn}`)
                .then(o => {
                    this.legalOrganization = o.data;
                });

            httpClient()
                .get(`/dadata?inn=${this.cardOrganization.inn}`)
                .then(info => {
                    this.dadataInfo = info.data;
                });
        }
    }
};
</script>

<style scoped lang="scss">
.card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 10px;
    padding: 26px 32px;
}

.short-name {
    font-weight: 500;
    font-size: 24px;
    margin-top: 40px;
}

.info-cards {
    display: flex;
    margin-top: 44px;
}

.main-info {
    display: flex;
    margin-top: 25px;

    .labels {
        width: 300px;
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
    }

    .values {
        line-height: 34px;
    }
}

.achievements {
    margin-top: 25px;
    display: flex;

    .label {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        width: 200px;
    }

    .achievement {
        text-align: center;
        position: relative;

        .value {
            font-weight: bold;
        }

        &:not(:last-child) {
            margin-right: 70px;
        }

        &:not(:last-child)::after {
            content: "";
            height: 40px;
            width: 1px;
            display: block;
            background: #d1d5db;
            position: absolute;
            right: -35px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.right-panel {
    margin-left: 35px;
    width: 350px;

    .label {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }

    .codes {
        margin-top: 28px;
        display: flex;

        .labels {
            width: 150px;
            line-height: 32px;
        }

        .values {
            line-height: 32px;
        }
    }

    .links {
        .label {
            margin-bottom: 20px;
        }

        a {
            margin-right: 15px;
        }
    }
}

.program {
    margin-bottom: 30px;

    .info {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

	.qual-name {
		margin: 10px 0;
	}
}
</style>
